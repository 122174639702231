import React, { useEffect } from 'react';
import { DataTimeline } from './DataTimeline';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

const Steps = () => {

    gsap.registerPlugin(ScrollTrigger);

    useEffect(() => {
        let tlValues = gsap.timeline({ scrollTrigger: { trigger: '.info-admission', start: '-400', markers: false, pin: false, toggleActions: "play", } });
  
        tlValues.fromTo(['.special-title', '.info-admission'], { opacity: 0, y: '50px', duration: 0.2, delay: 0.5 }, { opacity: 1, y: 0, stagger: 0.15 });
    }, []);

      useEffect(() => {
    document.querySelectorAll('.step').forEach((item) => {
      gsap.to(item, {
        scrollTrigger: {
          trigger: item,
          start: 'top center',
          scrub: false,
          markers: false,
          toggleActions: "play",
        },
        // clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
        opacity: 1,
        y: 0,
        duration: .3,
      })
    })
  }, []);

  return (
    <>
        <div className="layout bigger-mb">
            <div className="info-admission lg:max-w-1/2">
                <h2 className="secondary-title">
                    Procédure d'admission
                </h2>
                <p className="common-text">
                    Pour toute admission, il est indispensable d’avoir effectué un sevrage physique en milieu hospitalier ou en ambulatoire sous la responsabilité d’un  médecin. La procédure d’admission comprend un entretien d’information et une journée de stage. Le contrat stipule que la personne s’engage à rester abstinente durant son séjour et à respecter le cadre institutionnel.
                    <br />
                    <br />
                    La durée du séjour est déterminée en fonction des besoins de chacun/e, après une évaluation du dispositif cantonal d’indication <a  href="https://dcisa.ch/" target="_blank" rel='noreferrer' className="hover:text-green font-bold">DCISA</a>. Elle varie de 4 semaines au  minimum à plusieurs mois.
                </p>
            </div>
        </div>
        <div className="layout with-mb">
            <h2 className="special-title text-center">
                Étapes d'admission
            </h2>
            <div className="timeline-container lg:max-w-1/2 mx-auto">
                <div className="flex flex-col gap-y-12 md:gap-y-24">
                    {DataTimeline.map((DataTimeline, idx) => {
                        return (
                        <div key={idx} className="timeline-wrapper step flex flex-col md:flex-row items-center gap-4">
                            <div className="timeline-item order-2 md:order-1 max-w-card shadow-mainShadow rounded-mainRadius py-8 px-6 bg-white">
                                <h2 className="third-title">
                                    {DataTimeline.title}
                                </h2>
                                <p>
                                    {DataTimeline.content}
                                </p>
                            </div>
                            <div className="step-number rounded-mainRadius bg-white order-1 md:order-2">
                                <h2 className="step-number-title text-xl md:text-3xl font-bold flex items-center justify-center h-full shadow-mainShadow">
                                    {DataTimeline.step}
                                </h2>
                            </div>
                        </div>
                        )
                    })}
                    {/* <div className="timeline-wrapper flex">
                        <div className="timeline-item max-w-card shadow-mainShadow rounded-mainRadius py-8 px-6 bg-white">
                            <h2 className="third-title">
                                Critères d'admissions
                            </h2>
                            <p>
                                Avoir passé une évaluation médico-sociale.
                                Avoir effectué un sevrage physique en milieu hospitalier ou ambulatoire.
                                Avoir des capacités suffisantes dans la vie quotidienne.
                                Correspondre aux critères des principes de séjour.
                                Être d’âge adulte jusqu’à l’AVS.
                            </p>
                        </div>
                        <div className="step-number rounded-mainRadius bg-white">
                            <h2 className="step-number-title text-3xl font-bold flex items-center justify-center h-full shadow-mainShadow">
                                2
                            </h2>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    </>
  )
}

export default Steps