import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { gsap } from 'gsap';
import { Link } from 'react-router-dom';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import Services from './components/Services';

const Home = () => {

  useEffect(() => {
    document.querySelectorAll('.link-up').forEach(link => {
      link.addEventListener('click', function(){
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      })
    });
  }, []);

  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
      let tlValues = gsap.timeline({ scrollTrigger: { trigger: '.description-container', start: '-400', markers: false, pin: false, toggleActions: "play none none reverse", } });

      tlValues.fromTo(['.description-image', '.info'], { opacity: 0, y: '50px', duration: 0.2, delay: 0.5 }, { opacity: 1, y: 0, stagger: 0.15 });
  }, []);

  useEffect(() => {
    let tlMission = gsap.timeline({ scrollTrigger: { trigger: '.container-layout', start: '-400', markers: false, pin: false, toggleActions: "play none none reverse", } });

    tlMission.fromTo('.mission-background', { opacity: 0, duration: 0.2, }, { opacity: 1 })

    tlMission.fromTo(['.mission-title','.special-text'], { opacity: 0, y: '50px', duration: 0.2, delay: 0.5 }, { opacity: 1, y: 0, stagger: 0.25 });


}, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Fondation Estérelle-Arcadie</title>
      </Helmet>
    <header className="relative md:mb-14 lg:mb-32">
      {/* <div className="layout">
          <h1 className="main-title">Fondation Estérelle – Arcardie</h1>
      </div> */}
      <div className="layout-bigger lg:px-8 video-container xl:min-h-screen">
        <video className="video-desktop hidden md:block" muted autoPlay loop>
            <source src="video/intro-video-ea-desktop.mp4" type="video/mp4" />
            <source src="video/intro-video-ea-desktop.webm" type="video/webm" />
            Your browser does not support the video tag.
        </video>
        <video className="video-mobile md:hidden" muted autoPlay loop>
            <source src="video/intro-video-ea-mobile.mp4" type="video/mp4" media="all and (max-width: 480px)" />
            <source src="video/intro-video-ea-mobile.webm" type="video/webm" media="all and (max-width: 480px)" />
            Your browser does not support the video tag.
        </video>
      </div>
    </header>
    <div className="layout with-mb description-container">
      <div className="flex flex-col gap-8 lg:gap-0 lg:flex-row lg:items-center lg:justify-between w-full">
        <div className="description-image image-wrapper lg:max-w-5/12 reveal-element">
          <img src="img/esterelle-batiment.webp" alt="Bâtiment de l'Estérelle." />
        </div>
        <div className="info lg:max-w-1/2 reveal-element">
          <h3 className="text-xs md:text-sm mb-2 lg:mb-4">Qui sommes-nous</h3>
          <h2 className="secondary-title">La fondation</h2>
          <p className="common-text">
            La <b>Fondation Estérelle-Arcadie</b> est un centre de compétences addictologiques. Reconnue d’utilité publique, elle accompagne de manière résidentielle ou ambulatoire des personnes dépendantes à l’alcool et/ou à d’autres produits psychotropes dont les troubles associés n’excluent pas une autonomie et une capacité de réinsertion sociale.
          </p>
          <Link className="underline link-up link" to="/A-Propos">
            En Savoir Plus
          </Link>
        </div>
      </div>
    </div>
    <div className="layout with-mb description-container">
      <div className="flex flex-col gap-8 lg:gap-0 lg:flex-row lg:items-center lg:justify-between w-full">
        <div className="info lg:max-w-1/2 reveal-element">
          <h3 className="text-xs md:text-sm mb-2 lg:mb-4">Une étape importante</h3>
          <h2 className="secondary-title">Chères et chers visiteurs,</h2>
          <p className="common-text">
              Nous avons le plaisir de vous informer que la Fondation Estérelle-Arcadie a officiellement fusionné avec la Fondation L'Epi à Ménières. Cette fusion-acquisition  a été juridiquement finalisée le 30 juin 2024, avec effet rétroactif au 1er janvier 2024.
                <br />
                <br />
              Cette unification représente une étape importante dans notre engagement commun à offrir un soutien holistique et personnalisé aux personnes confrontées à des défis de santé mentale, d'addiction et de réinsertion sociale. En combinant nos forces, nos compétences et nos ressources, nous sommes mieux équipés encore pour répondre aux besoins de nos bénéficiaires et pour poursuivre notre mission avec une efficacité accrue.
                <br />
                <br />
              Nous vous invitons à suivre l'évolution de cette nouvelle entité unifiée, qui continue de porter les valeurs d'excellence, d'équité, de responsabilité et de collaboration. Notre engagement reste inchangé : offrir des prestations adaptées, respectueuses de la dignité de chaque personne et contribuer ainsi positivement à l’évolution de  la société.
                <br />
                <br />
              Merci de votre confiance et de votre soutien continu.
          </p>
        </div>
        <div className="description-image image-wrapper lg:max-w-5/12 reveal-element">
          <img src="img/handshake_2.webp" alt="Fusion entre l'Esterelle-Arcadie et la Fondation L'Epi." />
        </div>
      </div>
    </div>
    <div className="container-layout mission-background bg-green with-mb">
      <div className="layout">
        <div className="section lg:max-w-1/2 lg:mx-auto reveal-element">
          <h2 className="mission-title secondary-title">Notre Mission</h2>
          <p className="special-text">
            La FEA a pour mission de favoriser la réinsertion sociale des personnes en difficulté avec l’alcool et/ou d’autres produits psychotropes non prescrits, ainsi qu’avec des conduites menant à la dépendance. Concrètement, il s’agit de promouvoir la construction de nouveaux projets de vie, par un encadrement bio-psycho-social personnalisé, assuré par des professionnels dans un lieu de vie et à un rythme adaptés.
          </p>
        </div>
      </div>
    </div>
    <Services />
    {/* <Gallery /> */}
    </>
  )
}

export default Home