import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet';
import Steps from './components/Steps';
import Groups from './components/Groups';
import Bref from './components/Bref';
import { gsap } from 'gsap';

const Residentiel = () => {

    useEffect(() => {
        let tlCards = gsap.timeline({ ease: 'power3.inOut', duration: .25 });
    
        tlCards.to([".main-title", ".card-item"], { y: 0, opacity: 1, stagger: {amount: 0.25} });
      }, []);

  return (
    <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Fondation Estérelle-Arcadie | Résidentiel</title>
        </Helmet>
    <div className="layout with-mb with-pt">
        <h1 className="main-title text-4xl lg:text-8xl font-bold opacity-0 translate-y-6">Résidentiel</h1>
        <div className="card-container flex justify-end">
            <div className="card-item max-w-item relative appear-element">
                <div className="img-wrapper">
                    <img src="img/esterelle-batiment.webp" alt="L'institution l'Estérelle." />
                </div>
                <div className="card-info rounded-mainRadius shadow-mainShadow p-8 max-w-item-info relative -top-8 md:-left-20 bg-white">
                    <h2 className="secondary-title">
                        L'Estérelle
                    </h2>
                    <p className="mb-4">
                        Av. Nestlé 10 <br />
                        1800 Vevey
                    </p>
                    <ul>
                        <li>Tél: <a className="hover:text-green" href="tel:0219250303">+41 21 925 03 03</a></li>
                        {/* <li>Fax: +41 21 925 03 03</li> */}
                        <li><a className="hover:text-green" href="mailto:info@esterelle.ch">info@esterelle.ch</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="card-container">
            <div className="card-item max-w-item relative">
                    <div className="img-wrapper">
                        <img src="img/Arcadie.webp" alt="L'institution l'Estérelle." />
                    </div>
                    <div className="card-info rounded-mainRadius shadow-mainShadow p-8 max-w-item-info relative -top-8 md:-right-40 bg-white">
                        <h2 className="secondary-title">
                            L'Arcadie
                        </h2>
                        <p className="mb-4">
                            Rue Arthur-Mermod 4 <br />
                            1400 Yverdon-les-Bains
                        </p>
                        <ul>
                            <li>Tél: <a className="hover:text-green" href="tel:0244261115">+41 024 426 11 15</a></li>
                            {/* <li>Fax: +41 21 925 03 03</li> */}
                            <li><a className="hover:text-green" href="mailto:info@arcadie.ch">info@arcadie.ch</a></li>
                        </ul>
                    </div>
            </div>
        </div>
    </div>
    <Steps />
    <Groups />
    <Bref />
    </>
  )
}

export default Residentiel