import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

const Contact = () => {

  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
      let tlValues = gsap.timeline({ scrollTrigger: { trigger: '.contact-wrapper', start: '-500', markers: false, pin: false, toggleActions: "play none none reverse", } });

      tlValues.fromTo(['.contact-info', '.contact-map'], { opacity: 0, y: '50px', duration: 0.2, delay: 0.5 }, { opacity: 1, y: 0, stagger: 0.05 });
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Fondation Estérelle-Arcadie | Contact</title>
      </Helmet>
      <div className="layout with-mb with-pt">
        <h1 className="main-title-normal">
          Contactez-nous
        </h1>
      </div>
      <div className="layout with-mb">
        <div className="contact-wrapper h-high-layout flex flex-col md:flex-row md:justify-between">
          <div className="contact-info">
            <h2 className="secondary-title">
              L'Estérelle - Vevey
            </h2>
            <p className="mb-4">
              Av. Nestlé 10 <br />
              1800 Vevey
            </p>
            <ul className="mb-4">
              <li>Tél: <a className="hover:text-green" href="tel:0219250303">+41 21 925 03 03</a></li>
              <li><a className="hover:text-green" href="mailto:info@esterelle.ch">info@esterelle.ch</a></li>
            </ul>
          </div>
          <div className="contact-map h-1/2 md:h-full md:w-1/2 md:max-w-1/2 rounded-mainRadius shadow-mainShadow overflow-hidden">
            <iframe title="esterelle-map" className="h-full w-full" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2748.290290650249!2d6.8379463098379425!3d46.46275771961836!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478e9cd473029843%3A0x15bc430536e5125c!2sFondation%20Esterelle-Arcadie!5e0!3m2!1sen!2sch!4v1681899224421!5m2!1sen!2sch" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
      </div>
      <div className="layout with-mb">
        <div className="contact-wrapper h-high-layout flex flex-col md:flex-row md:justify-between">
          <div className="contact-info">
            <h2 className="secondary-title">
              L'Arcadie - Yverdon-Les-Bains
            </h2>
            <p className="mb-4">
              Rue Arthur-Mermod 4 <br />
              1400 Yverdon-les-Bains
            </p>
            <ul className="mb-4">
                <li>Tél: <a className="hover:text-green" href="tel:0244261115">+41 24 426 11 15</a></li>
                {/* <li>Fax: +41 21 925 03 03</li> */}
                <li><a className="hover:text-green" href="mailto:info@arcadie.ch">info@arcadie.ch</a></li>
            </ul>
          </div>
          <div className="contact-map h-1/2 md:h-full md:w-1/2 md:max-w-1/2 rounded-mainRadius shadow-mainShadow overflow-hidden">
            <iframe title="arcadie-map" className="h-full  w-full" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1184.645623022417!2d6.645939922500026!3d46.769133205639704!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478dcf9616f69b67%3A0x6f2d95b90216c2fc!2sFondation%20Esterelle%20Arcadie%20Yverdon%20Les%20Bains!5e0!3m2!1sen!2sch!4v1681910335249!5m2!1sen!2sch" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
      </div>
    </>
  )
}

export default Contact