import React, { useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

const Prestations = () => {

    gsap.registerPlugin(ScrollTrigger);

    useEffect(() => {
        let tlValues = gsap.timeline({ scrollTrigger: { trigger: '.prestation-container', start: '-400', markers: false, pin: false, toggleActions: "play none none reverse", } });

        tlValues.fromTo(['.prestation-title', '.prestation-item', '.devis'], { opacity: 0, y: '50px', duration: 0.1, delay: 0.5 }, { opacity: 1, y: 0, stagger: 0.1 });
    }, []);

    return (
        <div className="layout bigger-mb">
            <h2 className="prestation-title special-title text-center">
                Prestations de l'Atelier
            </h2>
            <div className="prestation-container with-mb grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 place-items-center gap-y-8">
                <div className="prestation-item">
                    <div className="prestation-icon">
                        <img className="" src="img/SVG/DataBase.svg" alt="Base de donnée." />
                    </div>
                    <ul className="text-center">
                        <li>Saisies numériques</li>
                        <li>Bases de données</li>
                        <li>Compilations de fichiers</li>
                    </ul>
                </div>
                <div className="prestation-item">
                    <div className="prestation-icon">
                        <img className="" src="img/SVG/Mailing.svg" alt="Mise sous pli." />
                    </div>
                    <ul className="text-center">
                        <li>Mailing</li>
                        <li>Assemblage</li>
                        <li>Mise sous pli</li>
                    </ul>
                </div>
                <div className="prestation-item">
                    <div className="prestation-icon">
                        <img className="" src="img/SVG/Packing.svg" alt="Confection de colis." />
                    </div>
                    <ul className="text-center">
                        <li>Confection de colis</li>
                        <li>Coffrets cadeaux</li>
                    </ul>
                </div>
                <div className="prestation-item">
                    <div className="prestation-icon">
                        <img className="" src="img/SVG/Analysis.svg" alt="Analyses." />
                    </div>
                    <ul className="text-center">
                        <li>Dépouillement concours</li>
                        <li>Analyses de réponses</li>
                        <li>Synthèse</li>
                    </ul>
                </div>
                <div className="prestation-item">
                    <div className="prestation-icon">
                        <img className="" src="img/SVG/Stock.svg" alt="Stockage." />
                    </div>
                    <ul className="text-center">
                        <li>Stockage produits dans un environnement sécurisé</li>
                    </ul>
                </div>
                <div className="prestation-item">
                    <div className="prestation-icon">
                        <img className="" src="img/SVG/Management.svg" alt="Gestion de stocks." />
                    </div>
                    <ul className="text-center">
                        <li>Conditionnement sur palette</li>
                        <li>Gestion de stocks</li>
                    </ul>
                </div>
            </div>
            <div className="devis">
                <h2 className="call-to-action-title text-center">
                    Vous souhaitez nous confier vos travaux ?
                    <br />
                    Demandez-nous une offre !
                </h2>
                    <a href="mailto:colliard@esterelle.ch">
                        <div className="devis-call-to-action">
                            Demander une offre
                        </div>
                    </a>
            </div>
        </div>
    )
}

export default Prestations