import './App.css';
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import About from './About';
import Contact from './Contact';
import Residentiel from './Residentiel';
import Atelier from './Atelier';
import CentreDejour from './CentreDejour';
import Appart from './Appart';
import Ambulatoire from './Ambulatoire';
import Nav from './components/Nav';
import Footer from './components/Footer';
import PageNotFound from './PageNotFound';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

function App() {

  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    document.querySelectorAll('.link-up').forEach(link => {
      link.addEventListener('click', function(){
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      })
    });
  }, []);

    useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);

  return (
    <div className="App">
      <Router>
        <Nav />
        {/* <ScrollToTop /> */}
          <div className="content min-h-screen">
            <Routes>
                <Route index element={<Home />} />
                <Route path="Residentiel" element={<Residentiel />} />
                <Route path="Atelier" element={<Atelier />} />
                <Route path="Centres-de-jours" element={<CentreDejour/>} />
                <Route path="Appartements-supervises" element={<Appart/>} />
                <Route path="Suivis-ambulatoires" element={<Ambulatoire/>} />
                <Route path="A-Propos" element={<About />} />
                <Route path="Contact" element={<Contact />} />
                <Route path="*" element={<PageNotFound />} />
            </Routes>
          </div>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
