import React, { useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

const Bref = () => {

    gsap.registerPlugin(ScrollTrigger);

    useEffect(() => {
            let tlBref = gsap.timeline( { scrollTrigger: { trigger: '.bref-contianer', start: '-200', markers: false, pin: false, toggleActions: "play", } } );
        
            tlBref.fromTo(['.secondary-title', '.bref-item'], { opacity: 0, duration: 0.2, delay: 0.5, y: 30 }, { opacity: 1, y: 0, stagger: 0.1 });
    }, []);

  return (
    <div className="layout with-mb">
        <div className="bref-container">
            <h2 className="secondary-title">
                En Bref, la résidence c'est ...
            </h2>
            <div className="bref-content">
                <div className="bref-item">
                    <h2 className="bref-item-title">
                        Un total de 48 places
                    </h2>
                </div>
                <div className="bref-item">
                    <h2 className="bref-item-title">
                        Un studio privé
                    </h2>
                </div>
                <div className="bref-item">
                    <h2 className="bref-item-title">
                        Des programmes résidentiels
                    </h2>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Bref