import React, { useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

const DataHistorique = [
    {
        Date: '1965',
        text: "Quelques personnes préoccupées par les problèmes des hommes et des femmes en difficulté avec l'alcool ont créé l'Association privée de prophylaxie de l'alcoolisme (APPA). Cette Association est formée alors des représentants de la plupart des institutions ou services sociaux s'occupant de problèmes liés à l'alcool. L'APPA s'est dissoute au profit de la Fondation Estérelle-Arcadie (FEA) en 1996.",
    },
    {
        Date: 'Estérelle à Vevey',
        text: "Une année après sa création, l’APPA ouvrait à Vevey l’Estérelle, institution spécialisée dans l’accompagnement et la réinsertion des personnes touchées par les problèmes d’alcool.",
    },
    {
      Date: 'Institution à Yverdon-les-Bains',
      text: "Compte tenu des résultats obtenus à l’Estérelle pendant ses années d’activité, ainsi que d’une évolution et d’une prise de conscience majeure du problème de la dépendance à l’alcool aux niveaux sociaux, médicaux et politiques, les autorités cantonales ont sollicité de l’APPA l’ouverture d’une deuxième institution dans le canton. L’Association inaugurait en octobre 1991 l’Arcadie à Yverdon-les-Bains.",
  },
  {
      Date: '',
      text: "Sous le nom de « Fondation Estérelle – Arcadie » a été créée une fondation, régie par les articles huitante et suivants du Code civil suisse, ainsi que par ses propres statuts.",
  },
  {
    Date: '',
    text: "La fondation a pour but de venir en aide à toute personne concernée par une dépendance à l’alcool, y compris tout autre problème et addiction associés; elle le réalise notamment par la création de centres de réadaptation socioprofessionnelle dans le canton de Vaud ou toute autre maison destinée à les accueillir; plus largement, par la collaboration à toute action en leur faveur sur le plan curatif, éducatif, préventif et social.",
  },
  {
    Date: '2024',
    text: "Nous avons fusionné la Fondation Estérelle-Arcadie avec la Fondation L'Epi à Ménières, une fusion finalisée le 30 juin 2024 avec effet rétroactif au 1er janvier 2024. Cette unification renforce notre capacité à offrir un soutien complet et personnalisé dans les domaines de la santé mentale, de l'addiction et de la réinsertion sociale. Nous continuons à adhérer aux valeurs d'excellence, d'équité, de responsabilité et de collaboration, tout en restant engagés à fournir des services adaptés et respectueux.",
  },
]

const Historique = () => {

  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
          let tlValues = gsap.timeline( { scrollTrigger: { trigger: '.special-title', start: '-600', markers: false, pin: false, toggleActions: "play", } } );
      
          tlValues.fromTo(['.special-title'], { opacity: 0, y: '50px', duration: 0.2, delay: 0.5 }, { opacity: 1, y: 0, stagger: 0.25 });
  }, []);

    useEffect(() => {
        document.querySelectorAll('.historique-item').forEach((item) => {
          gsap.to(item, {
            scrollTrigger: {
              trigger: item,
              start: 'top center',
              scrub: false,
              markers: false,
              toggleActions: "play none none reverse",
            },
            // clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
            opacity: 1,
            y: 0,
            duration: .3,
          })
        })
      }, []);

  return (
    <div className="layout bigger-mb">
        <h2 className="special-title text-center">
        Historique
        </h2>
        <div className="historique-container flex flex-col gap-8">
            {DataHistorique.map((DataHistorique, idx) => {
                return (
                <div key={idx} className="historique-item opacity-0 translate-y-10 md:max-w-card py-4 px-8 mx-auto rounded-mainRadius shadow-mainShadow bg-white">
                    <h3 className="historique-title text-center font-bold text-xl md:text-2xl mb-4">
                    {DataHistorique.Date}
                    </h3>
                    <p className="historique-text">
                        {DataHistorique.text}
                    </p>
                </div>
                )
            })}
        </div>
  </div>
  )
}

export default Historique