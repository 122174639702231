const DataTimeline = [
    {
        step: 1,
        title: "Critères d’admission",
        content: 'Avoir passé une évaluation médico-sociale. Avoir effectué un sevrage physique en milieu hospitalier ou ambulatoire. Avoir des capacités suffisantes dans la vie quotidienne. Correspondre aux critères des principes de séjour. Être d’âge adulte jusqu’à l’AVS.',
    },
    {
        step: 2,
        title: "Premier Entretien",
        content: "Présentation de la situation et évaluation de la personne Présentation de l’institution et de ses prestations. Remise des documents.",
    },
    {
        step: 3,
        title: "Journée de Stage",
        content: "Participation à la vie et au programme communautaire avec l’accompagnement d’un résident. Entretien administratif. Evaluation de la journée.",
    },
    {
        step: 4,
        title: "Traitement de la demande",
        content: "Discussion avec l'équipe et la direction lors d'un colloque.",
    },
    {
        step: 5,
        title: "Transmission de la décision",
        content: "Proposition d'une date d'entrée ou d'une autre orientation.",
    },
]

export { DataTimeline }