import React, { useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

const Rapport = () => {

  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    let tlRapport = gsap.timeline({ scrollTrigger: { trigger: '.rapport-wrapper', start: '-600', markers: false, pin: false, toggleActions: "play", } });

    tlRapport.fromTo(['.rapport-title'], { opacity: 0, y: '20px', duration: 0.2, delay: 0.5 }, { opacity: 1, y: 0, stagger: 0.25 });

  }, []);

  useEffect(() => {
    let tlLiens = gsap.timeline({ scrollTrigger: { trigger: '.rapport-container', start: '-550', markers: false, pin: false, toggleActions: "play", } });

    tlLiens.fromTo(['.rapport-item'], { opacity: 0, duration: 0.2 }, { opacity: 1, stagger: 0.05 });
  }, []);

  return (
    <div className="layout with-mb rapport-wrapper">
      <h2 className="secondary-title rapport-title">
        Rapports d'activités
      </h2>
      <div className="rapport-container">
        <a href="rapports/rapport-ea-2019.pdf" target="_blank" rel="noreferer">
          <div className="rapport-item">
            <h2>Rapport d'activité 2019</h2><img className="w-4" src="img/SVG/download-icon.svg" alt="Télécharger le document pdf." />
          </div>
        </a>
        <a href="rapports/rapport-ea-2020.pdf" target="_blank" rel="noreferer">
          <div className="rapport-item">
            <h2>Rapport d'activité 2020</h2><img className="w-4" src="img/SVG/download-icon.svg" alt="Télécharger le document pdf." />
          </div>
        </a>
        <a href="rapports/rapport-ea-2022.pdf" target="_blank" rel="noreferer">
          <div className="rapport-item">
            <h2>Rapport d'activité 2022</h2><img className="w-4" src="img/SVG/download-icon.svg" alt="Télécharger le document pdf." />
          </div>
        </a>
        <a href="https://issuu.com/esterelle-arcadie/docs/rapport_ea_2023_28p_v2" target="_blank" rel="noreferer">
          <div className="rapport-item">
            <h2>Rapport d'activité 2023</h2><img className="w-4" src="img/SVG/download-icon.svg" alt="Télécharger le document pdf." />
          </div>
        </a>
      </div>
    </div>
  )
}

export default Rapport