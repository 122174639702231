import React, { useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

const DataMembers = [
    {
        name: 'Me Christian FAVRE, Président',
        job: 'Avocat',
    },
    {
        name: "M. Gildo Dall'Aglio, Vice-Président",
        job: 'Directeur retraité, Centre social régional Yverdon-les-Bains',
    },
    {
        name: "Mme Christine Alexander Sax, Membre",
        job: 'Directrice Homme-Ecole romand – Les Buissonnets',
    },
    {
        name: "M. Marcel Yersin, Membre",
        job: 'Ancien Directeur Établissement scolaire',
    },
    {
        name: "M. Jean-Marc Dayer, Membre",
        job: 'Conseiller-vente',
    },
    {
        name: "M. Olivier Commend, Membre",
        job: 'Directeur financier retraité',
    },
    {
        name: "Mme Céline Murisier, Membre",
        job: 'Conseillère municipale Jongny',
    },
]

const Members = () => {

    gsap.registerPlugin(ScrollTrigger);

    useEffect(() => {
            let tlConseil = gsap.timeline( { scrollTrigger: { trigger: '.conseil-container', start: '-600', markers: false, pin: false, toggleActions: "play", } } );
        
            tlConseil.fromTo(['.conseil-title'], { opacity: 0, y: '20px', duration: 0.2 }, { opacity: 1, y: 0, stagger: 0.25 });

        }, []);

        useEffect(() => {
            let tlMembers = gsap.timeline( { scrollTrigger: { trigger: '.conseil-fondation-container', start: '-400', markers: false, pin: false, toggleActions: "play", } } );
        
            tlMembers.fromTo(['.conseil-fondation-item'], { opacity: 0, duration: 0.2 }, { opacity: 1, stagger: 0.05, delay: .2 });
    }, []);

  return (
    <div>
        <div className="layout conseil-container bigger-mb">
            <h2 className="secondary-title conseil-title">
            Conseil de Fondation
            </h2>
            <div className="conseil-fondation-container grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                {DataMembers.map((Member, idx) => {
                    return (
                        <div key={idx} id={idx} className="conseil-fondation-item px-4 py-2 rounded-mainRadius lg:h-24">
                            <h2 className="font-bold">
                            {Member.name}
                            </h2>
                            <h3 className="italic">
                            {Member.job}
                            </h3>
                        </div>
                    )
                })}
            </div>
        </div>
    </div>
  )
}

export default Members