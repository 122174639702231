import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import ObjectifCentre from './components/ObjectifCentre';

const CentreDejour = () => {

    gsap.registerPlugin(ScrollTrigger);

    useEffect(() => {
        let tlCards = gsap.timeline({ ease: 'power2.inOut', duration: .5 });
    
        tlCards.to([".main-title", ".card-item"], { y: 0, opacity: 1, stagger: {amount: 0.25} });
      }, []);

      useEffect(() => {
        let tlValues = gsap.timeline({ scrollTrigger: { trigger: '.page-section', start: '-400', markers: false, pin: false, toggleActions: "play", } });
    
        tlValues.fromTo(['.page-section .secondary-title', '.page-section .common-text'], { opacity: 0, y: '50px', duration: 0.2, delay: 0.5 }, { opacity: 1, y: 0, stagger: 0.15 });
    }, []);

  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>Fondation Estérelle-Arcadie | Centres de jours</title>
      </Helmet>
        <div className="layout with-mb with-pt">
            <h1 className="main-title text-4xl lg:text-8xl font-bold opacity-0 translate-y-6">Centres de jours</h1>
            <div className="card-container flex justify-end">
                <div className="card-item max-w-item relative appear-element">
                    <div className="img-wrapper">
                        <img src="img/Omoulin.webp" alt="L'institution l'Estérelle." />
                    </div>
                    <div className="card-info rounded-mainRadius shadow-mainShadow p-8 max-w-item-info relative -top-8 md:-left-20 bg-white">
                        <h2 className="secondary-title">
                            <img className="img-card" src="img/Logo_OMoulin.webp" alt="O'Moulin, Centre de Jour situé à Vevey." />
                        </h2>
                        <p className="mb-4">
                            Ruelle de l'Ancienne-Monneresse 5 <br />
                            1800 Vevey
                        </p>
                        <ul>
                            <li>Tél: <a className="hover:text-green" href="tel:0219210158">+41 21 921 01 58</a></li>
                            <li><a className="hover:text-green" href="mailto:contact@o-moulin.ch">contact@o-moulin.ch</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="card-container">
                <div className="card-item max-w-item relative">
                        <div className="img-wrapper">
                            <img src="img/Odeuxrives.webp" alt="L'institution l'Estérelle." />
                        </div>
                        <div className="card-info rounded-mainRadius shadow-mainShadow p-8 max-w-item-info relative -top-8 md:-right-40 bg-white">
                            <h2 className="secondary-title">
                            <img className="img-card" src="img/Logo_ODeuxRives.webp" alt="O'Deux Rives, Centre de Jour situé à Yverdons-les-Bains." />
                            </h2>
                            <p className="mb-4">
                                Rue de la Maison Rouge 4 <br />
                                1400 Yverdon-les-Bains
                            </p>
                            <ul>
                                <li>Tél: <a className="hover:text-green" href="tel:0245557515">+41 24 555 75 15</a></li>
                                <li><a className="hover:text-green" href="mailto:contact@o-deux-rives.ch">contact@o-deux-rives.ch</a></li>
                            </ul>
                        </div>
                </div>
            </div>
        </div>
        <div className="layout page-section with-mb">
            <h2 className="secondary-title">
                Une complémentarité...
            </h2>
            <p className="common-text">
                Ce projet permet à la <a target="_blank" rel='noreferrer' href="https://croix-bleue.ch/" className="font-bold hover:text-green">Croix-Bleue romande</a> (CBR)  et à la <b>Fondation Estérelle-Arcadie</b> (FEA), et plus largement au réseau alcoologique vaudois, de répondre à l’évolution des besoins, de diversifier les prestations tout en répondant à leur mission. La complémentarité entre l’offre résidentielle et celle ambulatoire apporte une réponse encore mieux adaptée aux besoins spécifiques de la personne en difficulté avec sa consommation d’alcool. Elle représente une ressource importante pour la personne qui a effectué un travail thérapeutique sur sa dépendance mais qui a encore besoin d’une stimulation, d’une structuration pour mener à bien ses objectifs d’autonomie.
            </p>
        </div>
        <ObjectifCentre />
        <div className="container-layout bg-cover">
            <div className="layout">
                <h2 className="secondary-title">
                    L'établissement
                </h2>
                <p className="rounded-mainRadius text-base lg:text-lg">
                    Les Centres de jours jouent un rôle de stabilisation dans la vie des personnes dépendantes à l’alcool.  Ils représentent des lieus avec des horaires fixes, des personnes connues, un rythme donné par des habitudes, une insertion dans une communauté ; ils permettent la recomposition d’un réseau social formé de ces personnes et d’intervenants aux statuts variés : personnel régulier, bénévoles, bénéficiaires de programmes,  etc. Les personnes qui se rendent dans ces centres savent qu’elles y seront toujours accueillies, qu’elles y viennent régulièrement ou non, pourvu qu’elles en respectent les règles fondamentales : le respect de soi et des autres, ne pas être sous  l’effet de l’alcool pour participer aux activités des Centres.
                </p>
            </div>
        </div>
    </>
  )
}

export default CentreDejour