import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { gsap } from 'gsap';

const Appart = () => {

    useEffect(() => {
        let tlCards = gsap.timeline({ ease: 'power3.inOut', duration: .25 });
    
        tlCards.fromTo([".main-title-normal",".special-text", ".info-container", ".appart-image"], {opacity: 0, y: 30}, { y: 0, opacity: 1, stagger: {amount: 0.25} });
      }, []);

  return (
    <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Fondation Estérelle-Arcadie | Appartements supervisés</title>
        </Helmet>
        <div className="layout with-mb with-pt">
            <h1 className="main-title-normal">Appartements Supervisés</h1>
            <p className="special-text mb-8">
                La fondation Estérelle-Arcadie propose également des places en appartements supervisés situés dans le canton de Vaud. Ces derniers visent à accompagner les personnes souhaitant mettre un pas de plus dans la vie active, tout en restant dans un cadre sécurisé et adapté à leurs problématiques.
            </p>
        </div>
        <div className="layout with-mb flex flex-col gap-8 lg:gap-0 lg:flex-row lg:justify-between lg:items-center">
            <div className="appart-image image-wrapper lg:max-w-5/12">
                <img src="img/vevey_landscape_1.webp" alt="Vue sur Vevey." />
            </div>
            <div className="info-container md:max-w-1/2">
                <h2 className="secondary-title">
                9 appartements répartis sur le canton...
                </h2>
                <ul className="common-list">
                    <li className="list-arrow">Offrent une transition entre le cadre institutionnel et la confrontation avec le monde extérieur</li>
                    <li className="list-arrow">Donnent au résident la possibilité d’expérimenter cette nouvelle phase de la vie sans alcool, avec l’appui et la sécurité procurés par la FEA.</li>
                    <li className="list-arrow">Permettent d’accompagner le résident dans sa globalité de vie en lui offrant la possibilité de se consacrer à la recherche d’un emploi, d’une activité stable, d’un logement, en bénéficiant d’un accompagnement institutionnel</li>
                </ul>
            </div>
        </div>
        {/* <div className="layout with-mb">
            <h2 className="special-title">Témoignages</h2>
            <div className="reviews-container grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                <div className="reviews-item rounded-mainRadius py-4 px-8">
                    Les appartements supervisés m'ont permis de reprendre une vie active en étant accompagné de manière ambulatoire.
                </div>
                <div className="reviews-item rounded-mainRadius py-4 px-8">
                    Les appartements supervisés m'ont permis de reprendre une vie active en étant accompagné de manière ambulatoire.
                </div>
                <div className="reviews-item rounded-mainRadius py-4 px-8">
                    Les appartements supervisés m'ont permis de reprendre une vie active en étant accompagné de manière ambulatoire.
                </div>
            </div>
        </div> */}
    </>
  )
}

export default Appart