import React, { useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { gsap } from 'gsap';

const Nav = () => {

    let [ menuState, setMenuState ] = useState(false);

    let sidebarNav = useRef(null);
    let burger = useRef(null);
    let navOverlay = useRef(null);
    const menuTimeline = useRef();

    // const nav = document.querySelector('.nav-container');

    useEffect(() => {

        menuTimeline.current = gsap.timeline({ paused: true });
        menuTimeline.current.to([navOverlay, sidebarNav],
          {
            duration: 0.75,
            x: '0%',
            ease: 'power3.inOut',
            stagger: {
              amount: 0.1
            }
          },
          menuTimeline.current.to([burger],{
            duration: 0.75,
            ease: 'power3.inOut',
            rotate: 90
          })
        );
    }, []);

    useEffect(() => {
        menuState ? menuTimeline.current.timeScale(1).play() : menuTimeline.current.timeScale(2).reverse();
    }, [menuState]);

  return (
    <div className="nav-container sticky md:relative  top-0 z-10 justify-between items-center mb-8 lg:pt-4 lg:px-8">
      <div className="nav-wrapper-top hidden lg:flex justify-end mb-2">
        <ul className="flex gap-4">
          <li><Link className="nav-link secondary link link-up" to="/A-Propos">À Propos</Link></li>
          <li><Link className="nav-link secondary link link-up" to="/Contact">Contact</Link></li>
        </ul>
      </div>
      <div className="nav-wrapper shadow-mainShadow lg:rounded-mainRadius flex justify-between items-center h-12 lg:h-16 bg-white">
          <div className="logo">
              <Link className="link-up" to="/">
                  <div className="logo text-2xl font-bold h-full flex items-center w-40">
                    <img src="img/Logo_Moyen.webp" alt="Fondation Estérelle-Arcadie" />
                  </div>
              </Link>
          </div>
          <button onClick={() => setMenuState(!menuState)} ref={el => (burger = el)} className={`burger-menu flex lg:hidden relative z-50 ${menuState ? 'burger-close' : ''}`}>
              <div></div>
              <div></div>
              <div></div>
          </button>
          <div onClick={() => setMenuState(false)}  ref={el => (navOverlay = el)} className="nav-overlay"></div>
          <nav ref={el => (sidebarNav = el)} className="nav-mobile">
              <ul>
                  <li><Link className="nav-link link-up" onClick={() => setMenuState(false)} to="/">Accueil</Link></li>
                  <li><Link className="nav-link link-up" onClick={() => setMenuState(false)} to="/Residentiel">Résidentiel</Link></li>
                  <li><Link className="nav-link link-up" onClick={() => setMenuState(false)} to="/Atelier">Atelier</Link></li>
                  <li><Link className="nav-link link-up" onClick={() => setMenuState(false)} to="/Centres-de-jours">Centres de jours</Link></li>
                  <li><Link className="nav-link link-up" onClick={() => setMenuState(false)} to="/Appartements-supervises">Appartements</Link></li>
                  <li><Link className="nav-link link-up" onClick={() => setMenuState(false)} to="/Suivis-ambulatoires">Suivis ambulatoires</Link></li>
                  <div className="secondary-links">
                      <li><Link className="nav-link link-up" onClick={() => setMenuState(false)} to="/A-Propos">À Propos</Link></li>
                      <li><Link className="nav-link link-up" onClick={() => setMenuState(false)} to="/Contact">Contact</Link></li>
                  </div>
              </ul>
          </nav>
          <nav className="hidden lg:block">
            <ul className="flex gap-x-6">
              <li><Link className="nav-link link-up link" to="/">Accueil</Link></li>
              <li><Link className="nav-link link-up link" to="/Residentiel">Résidentiel</Link></li>
              <li><Link className="nav-link link-up link" to="/Atelier">Atelier</Link></li>
              <li><Link className="nav-link link-up link" to="/Centres-de-jours">Centres de jours</Link></li>
              <li><Link className="nav-link link-up link" to="/Appartements-supervises">Appartements</Link></li>
              <li><Link className="nav-link link-up link" to="/Suivis-ambulatoires">Suivis ambulatoires</Link></li>
            </ul>
          </nav>
      </div>
    </div>
  )
}

export default Nav