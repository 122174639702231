import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Historique from './components/Historique';
import Liens from './components/Liens';
import { gsap } from 'gsap';
import Members from './components/Members';
import Rapport from './components/Rapport';

const About = () => {

  useEffect(() => {
    let tlCards = gsap.timeline({ ease: 'power3.inOut', duration: .25 });

    tlCards.fromTo([".about-info"], {y: '50px', opacity: 0}, { y: 0, opacity: 1, stagger: {amount: 0.25} });
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Fondation Estérelle-Arcadie | À Propos</title>
      </Helmet>
      <div className="layout about-info bigger-mb flex flex-col md:items-center with-pt">
        <h1 className="title-about">
          À Propos
        </h1>
        <div className="md:max-w-1/2">
          <h2 className="text-2xl font-bold mb-4 md:text-center">
            La Fondation Estérelle-Arcadie est un centre de compétences addictologiques.
          </h2>
          <p className="common-text md:text-center">
            Reconnue d'utilité publique, elle accompagne de manière résidentielle ou ambulatoire des personnes dépendantes à l'alcool et/ou à d'autres produits psychotropes dont les troubles associés n'excluent pas une autonomie et une capacité de réinsertion sociale.
          </p>
        </div>
      </div>
      <Historique id="historique" />
      <Members />
      <Liens />
      <Rapport />
    </>
  )
}

export default About