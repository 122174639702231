import React from 'react';
import { Link } from 'react-router-dom';

const PageNotFound = () => {
  return (
    <div className="h-screen flex justify-center items-center">
        <div className="text-center">
            <h2 className="text-3xl font-bold mb-2">Erreur 404</h2>
            <h3 className="text-3xl mb-8">Page inexistante</h3>
            <Link to="/" className="text-green text-2xl hover:opacity-75 font-bold">Retourner sur la page principale</Link>
        </div>
    </div>
  )
}

export default PageNotFound