import React, { useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

const DataLiens = [
    {
        title: 'Service de médecine des addictions du CHUV',
        web: 'https://www.chuv.ch/fr/fiches-psy/service-de-medecine-des-addictions-sma',
    },
    {
        title: "Fondation Vaudoise contre l'alcoolisme",
        web: 'http://www.fva.ch/',
    },
    {
        title: "Fondation l'Epi",
        web: 'http://www.lepi.ch/',
    },
    {
      title: "Fondation les Oliviers",
      web: 'https://oliviers.ch/',
    },
    {
      title: "CHUV Centre hospitalier universitaire vaudois",
      web: 'https://www.chuv.ch/fr/chuv-home/',
    },
    {
      title: "Médecins de premier recours",
      web: 'https://www.svmed.ch/',
    },
    {
      title: "Hôpital de Nyon",
      web: 'https://www.ghol.ch/',
    },
    {
      title: "Hôpital de Morges",
      web: 'https://www.ehc-vd.ch/',
    },
    {
      title: "Fondation de Nant",
      web: 'https://nant.ch/',
    },
    {
      title: "Centre ambulatoire spécialisé Azimut",
      web: 'https://www.vaudfamille.ch/N2317/azimut-centre-de-competences-dependances.html',
    },
]

const Liens = () => {

  gsap.registerPlugin(ScrollTrigger);

    useEffect(() => {
            let tlConseil = gsap.timeline( { scrollTrigger: { trigger: '.liens-container', start: '-600', markers: false, pin: false, toggleActions: "play", } } );
        
            tlConseil.fromTo(['.liens-title'], { opacity: 0, y: '20px', duration: 0.2, delay: 0.5 }, { opacity: 1, y: 0, stagger: 0.25 });

        }, []);

        useEffect(() => {
          let tlLiens = gsap.timeline( { scrollTrigger: { trigger: '.liens-container', start: '-400', markers: false, pin: false, toggleActions: "play", } } );
      
          tlLiens.fromTo(['.lien-item'], { opacity: 0, duration: 0.2 }, { opacity: 1, stagger: 0.05 });
  }, []);

  return (
    <div className="layout with-mb">
      <div className="liens-container">
          <h2 className="secondary-title liens-title">
            Autres services et établissements
          </h2>
          <div className="liens-wrapper mx-auto flex flex-col">
            {DataLiens.map((DataLiens, idx) => {
                return (
                    <a key={idx} href={`${DataLiens.web}`} target='_blank' rel='noreferrer'>
                        <div className="lien-item hover:text-green">
                            <h2 className="lien-title">{DataLiens.title}</h2>
                            <div className="redirect-icon w-4"><img src="img/SVG/redirect-arrow.svg" alt="Suivre le lien dans un nouvel onglet." /></div>
                        </div>
                    </a>
                )
            })}
          </div>
      </div>
    </div>
  )
}

export default Liens