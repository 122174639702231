import React, { useState, useEffect } from 'react';
import { DataGroups } from './DataGroups';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

const Groups = () => {

    const [active, setActive] = useState(false);

    gsap.registerPlugin(ScrollTrigger);

    useEffect(() => {
            let tlGroups = gsap.timeline( { scrollTrigger: { trigger: '.groups-container', start: '-400', markers: false, pin: false, toggleActions: "play", } } );
        
            tlGroups.fromTo(['.groups-card'], { opacity: 0, duration: 0.2, delay: 0.5 }, { opacity: 1, stagger: 0.05 });
    }, []);

    return (
        <div className="layout bigger-mb">
            <div className="groups-container gap-8 relative">
                <div className="groups-wrapper mb-24">
                    <h2 className="secondary-title">
                        Groupes
                    </h2>
                    <p className="common-text">
                        La FEA met à disposition différents groupes organisés dans la semaine visant à répondre aux divers besoins des résidents.

                        Chaque groupe à son objectif et fait partie du programme résidentiel.
                    </p>
                </div>
                <div className="groups-wrapper w-full grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                    {DataGroups.map((DataGroup, idx) => {
                        return (
                            <div key={DataGroup.id}>
                                <div onClick={() => { setActive(DataGroup) }} className={`groups-card rounded-mainRadius ${active === DataGroup && 'active'}`}>
                                    <div className="groups-card-lightbox-wrapper">
                                        <div onClick={(e) => {
                                                e.stopPropagation(); // and/or e.stopImmediatePropagation()
                                                setActive(null) 
                                            }} className="groups-lightbox-close-overlay absolute top-0 left-0 right-0 bottom-0 -z-10">

                                        </div>
                                        <div className="groups-card-lightbox pt-12 lg:pt-6">
                                            <h2 className="groups-card-lightbox-title">{DataGroup.title}</h2>
                                            <p className="groups-card-description">
                                                {DataGroup.description}
                                            </p>
                                            <div onClick={(e) => {
                                                e.stopPropagation(); // and/or e.stopImmediatePropagation()
                                                setActive(null) 
                                            }} className="absolute top-4 right-4 font-bold hover:text-green cursor-pointer">
                                                Fermer
                                            </div>
                                        </div>
                                    </div>
                                    <h2 className="groups-card-title">{DataGroup.title}</h2>
                                    {/* <p className="common-text">
                                        {DataGroups.description}
                                    </p> */}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default Groups