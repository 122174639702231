import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

const DataServices = [
    {
        image: 'Arcadie-esterelle.webp',
        title: 'Résidentiel',
        link: 'Residentiel',
    },
    {
        image: 'Atelier_devanture.webp',
        title: "Atelier de l'Esterelle",
        link: 'Atelier',
    },
    {
        image: 'Odeuxrives-moulin.webp',
        title: 'Centres de jours',
        link: 'Centres-de-jours',
    },
    {
        image: 'appart.webp',
        title: 'Appartements supervisés',
        link: 'Appartements-supervises',
    },
    {
        image: 'entretien.webp',
        title: 'Suivis ambulatoires',
        link: 'Suivis-ambulatoires',
    },
]

export { DataServices }


const Services = () => {

    useEffect(() => {
        document.querySelectorAll('.link-up').forEach(link => {
          link.addEventListener('click', function(){
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
          })
        });
      }, []);

    gsap.registerPlugin(ScrollTrigger);

    useEffect(() => {
        let tlValues = gsap.timeline({ scrollTrigger: { trigger: '.services-container', start: '-400', markers: false, pin: false, toggleActions: "play none none reverse", } });

        tlValues.fromTo(['.service-title', '.service-item'], { opacity: 0, y: '50px', duration: 0.15, delay: 0.5 }, { opacity: 1, y: 0, stagger: 0.2 });
    }, []);

    return (
        <div className="layout with-mb">
            <h2 className="service-title special-title">
                Nos Prestations
            </h2>
            <div className="services-container grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-24 md:gap-y-16 md:gap-x-8">
                {DataServices.map((DataServices, idx) => {
                    return (
                        <Link key={idx} className="link-up" to={`/${DataServices.link}`}>
                            <div className="service-item relative rounded-mainRadius cursor-pointer">
                                <div style={{
                                    backgroundImage: `url("img/${DataServices.image}")`
                                }} className="img-wrapper rounded-mainRadius overflow-hidden">
                                    {/* <img src={`img/${DataServices.image}`} alt="Studio." /> */}
                                </div>
                                <div className="prestation-link absolute -bottom-6 flex items-center justify-between bg-white rounded-mainRadius px-4 h-16 shadow-mainShadow text-xl font-bold">
                                    <div>
                                        {DataServices.title}
                                    </div>
                                    <div className="arrow">➔</div>
                                </div>
                            </div>
                        </Link>
                    )
                })}
                {/* <div className="service-item relative rounded-mainRadius cursor-pointer">
                <div className="img-wrapper rounded-mainRadius overflow-hidden">
                    <img src="img/studio-exemple.webp" alt="Studio." />
                </div>
                <div className="prestation-link absolute -bottom-6 flex items-center justify-between bg-white rounded-mainRadius px-4 h-16 shadow-mainShadow text-xl font-bold">
                    <Link to="/">
                    Résidentiel
                    </Link>
                    <div className="arrow">➔</div>
                </div>
            </div> */}
            </div>
        </div>
    )
}

export default Services