import React from 'react'

const ObjectifCentre = () => {
  return (
    <div className="layout with-mb">
    <div className="bref-container">
        <h2 className="secondary-title special-title">
            Objectifs du centre de jour
        </h2>
        <ul className="bref-centre">
            <li>➔ Établir une stabilisation dans la vie quotidienne</li>
            <li>➔ Mettre à disposition un espace sécurisant</li>
            <li>➔ Proposer une solution à la sociabilisation</li>
        </ul>
        {/* <div className="bref-content">
            <div className="bref-item">
                <h2 className="bref-item-title">
                    Stabilisation dans la vie quotidienne
                </h2>
            </div>
            <div className="bref-item">
                <h2 className="bref-item-title">
                    Un espace sécurisant
                </h2>
            </div>
            <div className="bref-item">
                <h2 className="bref-item-title">
                    Sociabilisation
                </h2>
            </div>
        </div> */}
    </div>
</div>
  )
}

export default ObjectifCentre