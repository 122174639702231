import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import Prestations from './components/Prestations';

const Atelier = () => {

    useEffect(() => {
        let tlCards = gsap.timeline({ ease: 'power3.inOut', duration: .25 });
    
        tlCards.to([".main-title", ".card-item"], { y: 0, opacity: 1, stagger: {amount: 0.25} });
      }, []);

        gsap.registerPlugin(ScrollTrigger);

      useEffect(() => {
        let tlValues = gsap.timeline({ scrollTrigger: { trigger: '.intro-atelier', start: '-400', markers: false, pin: false, toggleActions: "play", } });
    
        tlValues.fromTo(['.image-wrapper.first', '.info'], { opacity: 0, y: '50px', duration: 0.2, delay: 0.5 }, { opacity: 1, y: 0, stagger: 0.15 });
    }, []);

    useEffect(() => {
        let tlValues = gsap.timeline({ scrollTrigger: { trigger: '.second-container', start: '-400', markers: false, pin: false, toggleActions: "play", } });
    
        tlValues.fromTo(['.image-wrapper.second', '.second-container'], { opacity: 0, y: '50px', duration: 0.2, delay: 0.5 }, { opacity: 1, y: 0, stagger: 0.15 });
    }, []);
      
  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>Fondation Estérelle-Arcadie | Atelier</title>
    </Helmet>
    <div className="layout with-mb with-pt">
        <h1 className="main-title text-4xl lg:text-8xl font-bold opacity-0 translate-y-6">Atelier</h1>
        <div className="card-container flex justify-end">
            <div className="card-item max-w-item relative appear-element">
                <div className="img-wrapper">
                    <img src="img/Atelier_devanture.webp" alt="L'atelier de l'Esterelle." />
                </div>
                <div className="card-info rounded-mainRadius shadow-mainShadow p-8 max-w-item-info relative -top-8 md:-left-20 bg-white">
                    <h2 className="secondary-title">
                        Atelier de l'Estérelle
                    </h2>
                    <p className="mb-4">
                        Rue du Collège 2 <br />
                        1800 Vevey
                    </p>
                    <ul>
                        <li className="mb-2">Tél: <a className="hover:text-green" href="tel:0215661120">+41 21 566 11 20</a></li>
                        <li><a className="hover:opacity-75 p-2 bg-green rounded-mainRadius text-white inline-block" href="mailto:colliard@esterelle.ch">Contacter par mail</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div className="layout bigger-mb">
        <div className="flex intro-atelier flex-col gap-8 lg:gap-0 lg:flex-row-reverse lg:items-center lg:justify-between w-full">
            <div className="info lg:max-w-1/2 reveal-element">
            <h2 className="secondary-title">Atelier socialisant</h2>
            <p className="common-text">
            La Fondation Estérelle-Arcadie offre 17 places de travail à des personnes en difficultés sociales, encadrées par des professionnels des métiers de la logistique, du conditionnement et de la santé.
            Nous leur proposons une activité journalière valorisante et leur permettons ainsi de développer leur capacité professionnelle et de retrouver une autonomie.
            <br />
            <br />
            Un stage  en atelier peut être organisé afin de permettre au bénéficiaire de trouver et maintenir un rythme de travail, une confiance en soi, une stabilité correspondant aux exigences de l’économie. Au besoin, une orientation vers des services  spécialisés dans la  réinsertion professionnelle est organisée.
            </p>
            </div>
            <div className="image-wrapper first lg:max-w-5/12 reveal-element">
            <img src="img/exemple-travail.webp" alt="Exemple de travaux effectués à l'atelier de l'Estérelle." />
            </div>
        </div>
    </div>
    <div className="layout bigger-mb">  
        <div className="flex second-container flex-col gap-8 lg:gap-0 lg:flex-row lg:items-center lg:justify-between w-full">
                <div className="info lg:max-w-1/2 reveal-element">
                    <h2 className="secondary-title">Vous avez un projet, nous avons une solution</h2>
                    <p className="common-text">
                    Nous nous chargeons de toutes tâches commerciales sous la supervision d’un personnel hautement qualifié. Notre atelier travaille avec précision, à des prix compétitifs et dans le respect des délais.
                    Nos forces à votre service vous feront économiser de l’argent, gagner du temps et de l’énergie.
                    Nous sommes à votre disposition pour un devis sans engagement. Présentez-nous votre projet et nous vous ferons parvenir une offre dans les plus brefs délais.
                    </p>
                </div>
            <div className="image-wrapper second lg:max-w-5/12 reveal-element">
                <img src="img/atelier-job.webp" alt="Bâtiment de l'Estérelle." />
            </div>
        </div>
    </div>
    <Prestations />
    <div className="layout">
        <div className="with-mb flex justify-start">
            <div className="info lg:max-w-1/2">
                <h2 className="secondary-title">
                    Nos Atouts
                </h2>
                <p className="common-text">
                    L'atelier multi-services travaille en complémentarité, avec un équipement moderne et du personnel qualifié.
                    Tout sous un même toit, un seul répondant et proche de chez vous. Nous travaillons selon les critères d'hygiène et de sécurité pour les produits alimentaires & non alimentaires. Nous prenons en charge le service et la livraison entre vos différents sites.
                </p>
            </div>
        </div>
        <div className="with-mb flex flex-col gap-8 lg:gap-0 lg:flex-row lg:justify-between lg:items-center">
            <div className="atelier-image image-wrapper lg:max-w-5/12">
                <img src="img/atelier_esterelle.webp" alt="Atelier de l'Estérelle." />
            </div>
            <div className="info lg:max-w-1/2">
                <h2 className="secondary-title">
                    Le but de l'Atelier
                </h2>
                <p className="common-text">
                En offrant du travail à des personnes qui s’engagent à se reconstruire dans un lieu protégé de l’alcool, vous permettez de retirer la partie visible de l’iceberg. Et ainsi à celle qui est cachée de refaire surface.
                </p>
            </div>
        </div>
    </div>
    </>
  )
}

export default Atelier