const DataGroups = [
    {
        id: 1,
        title: 'Affirmation de soi',
        image: 'affirmation.jpg',
        description: 'Augmenter vos compétences sociales, afin d’agir au mieux de vos intérêts,\n apprendre à défendre votre point de vue sans anxiété exagérée, communiquer avec plus d’efficacité, exprimer avec aisance et sincérité vos sentiments sans dénigrer ceux des autres. Cet apprentissage repose sur différents outils, tels que l’analyse fonctionnelle, des jeux de rôle, des techniques de relaxation et de restructuration cognitive. Cet atelier fait partie des modules d’entraînement aux habiletés sociales, basés sur les principes de l’apprentissage social selon Bandura.',
    },
    {
        id: 2,
        title: 'Atelier Diététique',
        image: 'diététique.jpg',
        description: "L’alimentation saine participe à la reconstruction des personnes en difficulté avec l’alcool. Depuis quelques années, nous avons le plaisir d’animer des ateliers diététiques pratiques qui se déroulent sur une demi- journée, en petits groupes de trois ou quatre personnes. Les objectifs principaux sont L’approche de la notion d’équilibre alimentaire par l’élaboration d’un menu équilibré, à partir de recettes et de la créativité de chacun La gestion d’un petit budget donné L’adaptation de recettes sans alcool Le partage du repas: échange des réalisations de chacun, respect du ressenti de la satiété, plaisir des sens, de la convivialité… Le choix du menu, la réalisation des achats, la confection et la dégustation du repas, ainsi que les rangements de la cuisine sont les moments qui remplissent cette demi-journée. Dans une ambiance «comme à la maison», ce sont aussi des instants qui mettent en lumière des outils pratiques que certains sauront utiliser chez eux afin de gérer l’aspect financier d’une alimentation savoureuse, saine et variée.",
    },
    {
        id: 3,
        title: 'Art-Thérapie',
        image: 'art.jpg',
        description: "C’est en jouant, et seulement en jouant que l’individu, enfant ou adulte, est capable d’être créatif et d’utiliser sa personnalité toute entière. C’est seulement en étant créatif que l’individu découvre le Soi. D.W. Winnicott, «Jeu et réalité», 1975, p.76. L’art-thérapie est centrée sur le processus créatif sans viser un but esthétique et prend en considération la dimension globale de l’être. Ainsi pour s’exprimer, représenter ses sentiments, pensées, souvenirs, conflits, ressources, le participant à l’atelier utilise les matières propres aux arts plastiques et visuels. Le choix de la technique utilisée est laissé en principe au participant en fonction de son besoin et de sa sensibilité. La notion de plaisir et de découverte sont au cœur de cette démarche. Symboliquement au travers de ce mode d’expérimentation et de jeu, dans le cadre protégé de l’atelier, la personne peut progressivement observer et identifier ses processus de fonctionnement, développer ses capacités d’adaptation et, là où elle cherche de nouvelles solutions, devenir auteur de sa propre transformation.Cet atelier de deux heures est hebdomadaire peut accueillir une dizaine de personnes. Le cadre posé implique la confidentialité, la bienveillance mutuelle et l’engagement à la régularité.",
    },
    {
        id: 4,
        title: 'Approche Corporelle',
        image: 'affirmation.jpg',
        description: "Lorsque le mental et les émotions nous envahissent, il nous reste un allié, notre corps ! Les résidents de l’Arcadie l’apprennent par diverses méthodes : La relaxation, la respiration et les mouvements conscients. Ainsi, en s’arrêtant sur ses sensations corporelles, en apprenant à apaiser son système nerveux, ce qui amène à calmer ses angoisses, le résident acquiert la possibilité de se rapprocher de ses ressources vitales.",
    },
    {
        id: 5,
        title: 'Chambre Noire',
        image: 'affirmation.jpg',
        description: "Cet atelier vous propose, à Yverdon-les-Bains, de découvrir et de vous réapproprier votre environnement par l’intermédiaire du médium photographique et de le transmettre avec votre propre regard à un large public. Les sessions durent 3 mois. Passé ce délai, il existe la possibilité d’utiliser l’infrastructure de manière indépendante afin de construire un projet photographique avec un accompagnement ponctuel",
    },
    {
        id: 6,
        title: 'Coaching Administratif',
        image: 'coaching.jpg',
        description: "Vous rencontrez peut-être des difficultés à gérer vos tâches administratives et à effectuer des démarches courantes telles que la gestion du courrier, la demande d’un arrangement de paiement. Cette situation inconfortable est souvent source d’anxiété et interfère avec le bon déroulement d’un travail sur soi. Nous vous offrons le soutien nécessaire, des conseils personnalisés et des outils adaptés pour que vous appreniez à tenir à jour vos papiers, votre correspondance, vos paiements et documents fiscaux. Des ateliers administratifs sont parfois organisés sur les thèmes de l’assurance maladie, la gestion du budget, etc.",
    },
    {
        id: 7,
        title: 'Entreriens, Thérapies de famille',
        image: 'affirmation.jpg',
        description: "Lorsqu’un proche parent entre en post-cure, sa démarche engendre notamment un soulagement, beaucoup de questions et parfois aussi de l’appréhension. Le changement de la personne au cours du séjour résidentiel va engendrer de nombreuses répercussions autour d’elle, qui souvent amènent une nouvelle dynamique familiale. Retrouver une place ou «sa» place est un enjeu primordial, mais cela nécessite souvent des ajustements et de nouveaux «éclairages». C’est pourquoi les entretiens ou thérapies de famille proposent une lecture et une approche neuves entraînant parfois une redéfinition de la famille constituée.",
    },
    {
        id: 8,
        title: 'Improthérapie',
        image: 'affirmation.jpg',
        description: "C’est un atelier d’art-thérapie à vocation théâtrale. Il ne s’agit en tout cas pas de réciter un texte, mais d’exprimer à travers des scènes de la vie quotidienne un vécu intérieur.",
    },
    {
        id: 9,
        title: 'Famille et Proches',
        image: 'affirmation.jpg',
        description: "Ce groupe a pour objectif de réfléchir aux relations familiales passées, présentes et futures de chaque participant. En effet, chacun évoque le contexte familial dans lequel il a grandi pour pouvoir faire des liens avec ce qu’il est et «porte» aujourd’hui. Les notions telles que les loyautés inconscientes, les liens, les règles et rôles familiaux, les deuils, les résonnances sont abordées.",
    },
    {
        id: 10,
        title: 'Groupes de Parole',
        image: 'affirmation.jpg',
        description: "Apprendre à parler de soi, à faire part de ses émotions et à s’enrichir de l’expérience des autres au sein d’un espace de confiance. Autour de différents thèmes, vous apprenez à identifier les facteurs déclencheurs de votre mal-être et acquérez progressivement des outils vous permettant de vous ouvrir, de retrouver une vie autonome et de redonner des valeurs, un sens à votre vie.",
    },
    {
        id: 11,
        title: 'Information alcoologique',
        image: 'affirmation.jpg',
        description: "Ce groupe s’inscrit comme une prestation des institutions de la FEA dans l’ensemble de leur programme institutionnel. Il a pour but d’offrir à toute personne arrivant dans une institution une formation alcoologique de base permettant une bonne compréhension des phénomènes de la dépendance. Pour atteindre cet objectif, il développe en particulier les notions de l'alcool, produit psychotrope et toxique.",
    },
    {
        id: 12,
        title: 'Prévention de la Rechute',
        image: 'affirmation.jpg',
        description: "Prévenir la rechute est le défi majeur pour toute personne dépendante. La prévention de la rechute est une approche utilisée avec des personnes qui ont pris la décision de s’engager dans un processus d’abstention d’alcool et / ou d’autres psychotropes. Inspirée de l’approche cognitivo-comportementale, elle vise principalement à ider les résidents à maintenir leur décision, les sensibiliser aux situations à risque et à leur apprendre, en cas d’écart de consommation, à stopper le processus et prévenir une rechute massive.",
    },
    // {
    //     id: 13,
    //     title: 'Sens à la Vie',
    //     image: 'affirmation.jpg',
    //     description: "Ceci est une description",
    // },
    // {
    //     id: 14,
    //     title: 'Suivi Social Externe',
    //     image: 'affirmation.jpg',
    //     description: "Ceci est une description",
    // },
    // {
    //     id: 15,
    //     title: 'Thérapies Individuelles',
    //     image: 'affirmation.jpg',
    //     description: "Ceci est une description",
    // },
];

export { DataGroups }