import React, { useEffect } from 'react';

const Footer = () => {

    useEffect(() => {
        document.querySelectorAll('.link-up').forEach(link => {
          link.addEventListener('click', function(){
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
          })
        });
      }, []);

  return (
    <div className="footer bg-white pt-9 lg:pt-14 pb-6">
        <div className="layout with-mb flex flex-col lg:flex-row justify-between">
            <p className="footer-text text-base lg:max-w-1/2 italic">
            À toutes les personnes qui recherchent un traitement à leur dépendance à l’alcool, je veux dire très fort qu’elles ne sont pas seules dans ce processus de changement. Elles pourront toujours compter sur l’accompagnement de notre fondation. Tel est notre objectif, telle est notre volonté !
            <br />
            <br />
            M. Chenaux, Directeur
            </p>
        </div>
        <div className="layout with-mb flex flex-col gap-6 lg:flex-row justify-between">
            <div className="footer-left">
                <div className="footer-wrapper flex flex-col lg:flex-row gap-8 lg:gap-14 mb-14">
                    <div className="footer-item">
                        <h2 className="footer-secondary-title">
                            L'Estérelle
                        </h2>
                        <h3 className="footer-third-title">
                            Siège Social
                        </h3>
                        <p className="footer-text mb-4">
                            Av. Nestlé 10 <br />
                            1800 Vevey
                        </p>
                        <ul className="footer-text">
                            <li>Tél: <a href="tel:0219250303">+41 21 925 03 03</a></li>
                            {/* <li>Fax: +41 21 925 03 03</li> */}
                            <li><a href="mailto:info@esterelle.ch">info@esterelle.ch</a></li>
                        </ul>
                    </div>
                    <div className="footer-item">
                        <h2 className="footer-secondary-title">
                            L'Arcadie
                        </h2>
                        <h3 className="footer-third-title">
                            Site Yverdon
                        </h3>
                        <p className="footer-text mb-4">
                            Rue Arthur-Mermod 4 <br />
                            1400 Yverdon-les-Bains
                        </p>
                        <ul className="footer-text">
                            <li>Tél: <a href="tel:0244261115">+41 24 426 11 15</a></li>
                            {/* <li>Fax: +41 24 426 11 17</li> */}
                            <li><a href="mailto:info@arcadie.ch">info@arcadie.ch</a></li>
                        </ul>
                    </div>
                    <div className="footer-item">
                        <h2 className="footer-secondary-title">
                            Ateliers
                        </h2>
                        <h3 className="footer-third-title">
                            Atelier de l'Estérelle
                        </h3>
                        <p className="footer-text mb-4">
                            Rue du Collège 2 <br />
                            1800 Vevey
                        </p>
                        <ul className="footer-text">
                            <li>Tél: <a href="tel:0215661120">+41 21 566 11 20</a></li>
                            <li><a href="mailto:colliard@esterelle.ch">colliard@esterelle.ch</a></li>
                        </ul>
                    </div>
                </div>
                <div className="footer-wrapper flex flex-col lg:flex-row gap-8 lg:gap-14 mb-14">
                    <div className="footer-item">
                        <h2 className="footer-secondary-title">
                            O'Moulin
                        </h2>
                        <h3 className="footer-third-title">
                            Centre de jour Vevey
                        </h3>
                        <p className="footer-text mb-4">
                            Rue Ancienne-Monneresse 5 <br />
                            1800 Vevey
                        </p>
                        <ul className="footer-text">
                            <li>Tél: <a href="tel:0219210158">+41 21 921 01 58</a></li>
                            {/* <li>Fax: +41 21 925 03 03</li> */}
                            <li><a href="mailto:contact@o-moulin.ch">contact@o-moulin.ch</a></li>
                        </ul>
                    </div>
                    <div className="footer-item">
                        <h2 className="footer-secondary-title">
                            O'Deux Rives
                        </h2>
                        <h3 className="footer-third-title">
                            Centre de jour Yverdon-les-Bains
                        </h3>
                        <p className="footer-text mb-4">
                            Rue de la Maison Rouge 4 <br />
                            1400 Yverdon-les-Bains
                        </p>
                        <ul className="footer-text">
                            <li>Tél: <a href="tel:0245557515">+41 24 555 75 15</a></li>
                            {/* <li>Fax: +41 24 426 11 17</li> */}
                            <li><a href="mailto:contact@o-deux-rives.ch">contact@o-deux-rives.ch</a></li>
                        </ul>
                    </div>
                </div>
                <div className="footer-wrapper">
                    <div className="footer-item">
                        <img className="w-40" src="img/Logo_Moyen.webp" alt="L'Estérelle." />
                    </div>
                </div>
                </div>
                {/* <div className="footer-right">
                    <div className="footer-wrapper flex gap-8">
                        <div className="footer-item w-8 h-8 lg:w-24 lg:h-24 rounded-full flex justify-center items-center bg-grey">
                            Certifications
                        </div>
                        <div className="footer-item w-8 h-8 lg:w-24 lg:h-24 rounded-full flex justify-center items-center bg-grey">
                            Certifications
                        </div>
                        <div className="footer-item w-8 h-8 lg:w-24 lg:h-24 rounded-full flex justify-center items-center bg-grey">
                            Certifications
                        </div>
                    </div>
                </div> */}
            </div>
            <div className="credits text-xs text-center text-grey">Fondation Estérelle-Arcadie © 2024</div>
    </div>
  )
}

export default Footer