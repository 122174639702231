import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { gsap } from 'gsap';

const Ambulatoire = () => {

  useEffect(() => {
    let tlCards = gsap.timeline({ ease: 'power3.inOut', duration: .25 });

    tlCards.fromTo([".main-title-normal",".special-text", ".ambulatoire-image", ".ambulatoire-info-wrapper"], {y: '50px', opacity: 0}, { y: 0, opacity: 1, stagger: {amount: 0.25} });
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Fondation Estérelle-Arcadie | Suivis ambulatoires</title>
      </Helmet>
      <div className="layout with-mb with-pt">
          <h1 className="main-title-normal opacity-0 translate-y-4 mb-4 md:mb-10">Suivis Ambulatoires</h1>
          <p className="special-text">
          La mission d’accompagnement de personnes dépendantes dans une démarche d’abstinence et de réinsertion sociale et/ou professionnelle de la FEA perdure au-delà du séjour résidentiel. C’est pourquoi nous proposons aux résidents intéressés un suivi ambulatoire à la fin de leur séjour résidentiel. Ainsi nous accompagnons la personne face aux difficultés qu’elle peut rencontrer lors de son retour à domicile ou dans une autre structure. Les suivis ambulatoires ont également pour but d’accompagner la personne dans le maintien de ses acquis, dans l’utilisation de ses compétences et de ses ressources. L’objectif de la FEA est de se positionner comme facilitateur, afin de permettre à la personne d’atteindre ses propres objectifs.
          </p>
      </div>
      <div className="layout with-mb">
        <div className="flex flex-col gap-8 lg:flex-row lg:gap-0 lg:items-center lg:justify-between">
          <div className="ambulatoire-image lg:max-w-5/12">
            <img src="img/entretien.webp" alt="Entretien entre deux personnes." />
          </div>
          <div className="ambulatoire-info-wrapper lg:max-w-1/2">
            <h2 className="secondary-title">
              Après votre séjour résidentiel
            </h2>
            <p className="common-text">
              Concrètement, au terme du séjour, il est possible de continuer à bénéficier de prestations et d'un accompagnement individuel.
            </p>
            <ul className="common-list">
              <li className="list-arrow">Bénéficier d'un soutien socio-thérapeutique au long cours ;</li>
              <li className="list-arrow">Conserver la confiance en soi ;</li>
              <li className="list-arrow">Maintenir les habiletés acquises durant son séjour à la FEA ;</li>
              <li className="list-arrow">Profiter d'un réseau d'entraide constitué de personnes qui partagent un vécu comparable au sien ;</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default Ambulatoire